
.main-footer .footer-drop {
    position: relative;
    width: 200px;
  }
  
  .main-footer .footer-drop h2 {
    
    border-radius: 2px;
    position: relative;
    margin-bottom: 0;
    display: flex;
    flex: 1;
    padding-left: 73px;
    /* justify-content: center; */
    align-items: center;
    box-shadow: 4px 4px 20px -2px rgba(0, 0, 0, 0.35);
    transition: all 0.4s;
  }
  
  .main-footer .footer-drop:hover h2 {
    transform: translateY(-2px);
    box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.35);
    transition: all 0.4s;
  }
  
  .main-footer #toggle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    height: 20px;
  }
  
  .main-footer h2::before {
    position: absolute;
    right: 75px;
    top: 3px;
    height: inherit;
    aspect-ratio: 1;
    background: currentColor;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    content: "";
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%); 
    transform: rotate(180deg) scale(0.50);
    transition: transform 0.3s;
  }
  
  .main-footer #toggle:checked ~ h2::before {
    transform: rotate(0deg) scale(0.50);
  }


  
  .main-footer .footer-drop ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    max-height: 0; /* Initially collapsed */
    transition: max-height 0.4s ease; /* Smooth transition */
    color: white;
  }
  
  .main-footer #toggle:checked ~ ul {
    max-height: 300px; /* Set a maximum height that fits your content */
  }
  
  .main-footer .footer-drop ul li {
    padding: 0.5rem;
    text-align: center;
  }
  
  
  
  .main-footer .footer-drop ul a {
    text-decoration: none;
    color: inherit;
  }
  
  
  .main-footer .footer-drop #toggle:checked ~ ul {
    max-height: 300px; /* Adjust as needed for content height */
  }
  
  .main-footer .footer-drop ul {
    /* Collapsed state styles */
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;
  }